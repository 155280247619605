.root {
  width: 60%;

  h6 {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 90%;
  }

  .files {
    background-color: #EEF7EF;
    min-height: 200px;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .drop {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 24px;
    }
  }

  .fileList, .docList {
    display: flex;
    flex-direction: column;
    font-size: 85%;
    margin-top: 1rem;
  }

  .doc, .file {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
  }

  .file {
    background-color: #EEF7EF;
    align-items: center;

    .fileName {
      flex: 1;
      font-weight: 600;
    }

    button {
      padding: 0;
    }
  }

  .doc {
    display: flex;
    flex-direction: column;

    background-color: white;
    
    button {
      padding: 0;
      margin-left: 1rem;
      color: var(--bs-secondary);
    }

    .docContainer {
      display: flex;
    }

    .docData {
      flex: 1;
      display: flex;
      flex-direction: column;

      .docFileName {
        font-weight: 600;
      }
      .docDate {
        min-width: 120px;
      }
    }

    .docDeleted {
      display: flex;
      margin-top: 0.5rem;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      background-color: #f8d7da;
      border-color: #f5c2c7;
      color: #842029;

      .msg {
        flex: 1;
      }

      button {
        padding: 0;
        font-size: 0.75rem;
        color: #dc1526;
        font-weight: 600;
      }
    }
  }
}