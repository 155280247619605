.root {
  display: flex;
  flex-direction: column;

  padding: 1.75rem 0.6rem;

  .title {
    font-size: 1.2rem;
    padding: 0.75rem;
  }

  .footer {
    padding: 0.75rem 0 0.6rem 0.75rem;
    font-size: 1rem;
    display: flex;
    align-items: center;

    i {
      color: var(--bs-primary);
    }

    .date {
      flex: 1;
    }

    button {
      padding: 0.3rem 2.5rem;
      // padding-left: 3rem;
      // padding-right: 3rem;
    }
  }
}