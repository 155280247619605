#profile {
  .profile-menu {
    position: sticky;
  }
  .picture {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 1rem;
    font-size: 60px;
  }
  .name {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .section-form {
    padding-bottom: 1rem;
  }
  form {
    height: 100%;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 71px);
    overflow-y: scroll;
  }
  .form-footer {
    border-top: 1px solid #7070704d;
    padding: 1rem 0;
    margin-top: 2rem;
    display: flex;
    .required-legend {
      flex: 1;
    }
  }
  h3 {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #7070704d;
    margin-bottom: 1.5rem;
  }
  .section-nav {
    font-size: 0.95rem;
    padding: 0.5rem 0;
    color: inherit;
    cursor: pointer;

    &.active {
      color: var(--bs-primary);
      font-weight: bold;
    }
  }
}