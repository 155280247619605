.root {
  display: flex;
  align-items: center;
  padding: 1rem 0;

  font-size: 9pt;

  a.active {
    font-weight: bold;
  }
  a.parent {
    color: inherit;
  }

  .separator {
    padding: 0 0.5rem;
  }
}