label.entity-type-option {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.checked {
    border: 2px solid;
    border-color: var(--bs-primary);
    font-weight: bold;
    color: var(--bs-primary);
    background-color: #f3f3f3;
  }

  i {
    font-size: 30px;
    padding-bottom: 0.25rem;
  }

  input {
    display: none;
  }
}