.root {
  display: flex;
  padding: 2rem 3rem;
  align-items: center;

  color: #707070;

  

    .icon {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .img {
        width: 32px;
        height: 32px;
        display: inline-block;
        background: white;
      }

      .dollar {
        font-size: 32px;
        color: white;
      }
    }

    .iconImg {
      background-size: cover;
      background-position: 50% 50%;
    }

    // .data {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // flex: 1;

    font-size: 1rem;
    font-weight: bold;

    // .title {
      // display: inline-block;
      // margin-bottom: 0.4rem;
    // }

    .valueContainer {
      display: flex;
      flex-direction: column;

      .value {
        flex: 1;
        // font-size: 1.5rem;
        font-weight: bold;
      }
      .state {
        padding: 0.4rem 0.75rem;
        border-radius: 1.5rem;
        color: white;
        display: flex;
        align-items: center;
      }
      .stateValue {
        font-weight: bold;
      }
    }

    .period {
      // font-size: 0.7rem;
    }
  // }



  &:hover .data .title {
    color: var(--bs-primary);
  }

  &.selected {
    color: white;

    .icon {
      border: 2px solid white;
    }
  }
}

.selected {
  .root {
    border: 1px solid var(--bs-primary);
    padding: calc(2rem - 1px) calc(1.5rem - 1px);
  }
}