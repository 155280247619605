main#login {
  height: 100vh;
  display: flex;

  .login-image,
  .login-form {
    width: 50%;
  }

  .login-image {
    height: 100%;
    background-image: url(../../img/login-background.png);
    background-size: cover;

    .overlay {
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .login-form {
    height: 100%;
    background-image: url(../../img/page-bg.png);
    background-position: 100%;
    background-size: cover;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 8rem 8rem;

    h3 {
      margin-bottom: 3rem;
    }
    h5 {
      margin-bottom: 1.5rem;
    }
    
    form {
      display: flex;
      flex-direction: column;

      .btn {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    a {
      color: #286F3D;
    }
    
    button {
      text-transform: uppercase;
    }

    img#logo {
      align-self: flex-end;
    }
  }
}