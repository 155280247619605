.root {
  padding: 1.5rem 0;
  
  .container {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: visible;
    padding: 0;

    &.frozen {
      margin-left: 36.75rem;
    }
  }

  h4 {
    padding-left: 1.5rem;
    font-size: 14pt;
  }
}