.data-form {
  flex: 1;
  display: flex;
  flex-direction: column;

  h3 {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #7070704d;
    margin-bottom: 1.5rem;
  }

  .form-wrapper {
    display: flex;
    flex: 1;
    padding-bottom: 2rem;
    border-bottom: 1px solid #7070704d;
    margin-bottom: 2rem;
    max-height: 500px;

    .form-content {
      flex: 1;
      overflow-y: scroll;
    }
    .form-side {
      display: flex;
      align-items: flex-end;
      margin-left: 2rem;
    }
  }

  .navigation {
    display: flex;

    .required-legend {
      flex: 1;
    }
  }
}