.banner-container {
  height: 468px;
  position: relative;
  width: 100%;
  z-index: 1;

  .banner {
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
  }

  .banner-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0,0,0,0.3);
    z-index: 1;
  }
}