section#investments {
  padding: 150px 1rem 0;

  .title {
    padding-bottom: 2rem;
    border-bottom: 1px solid #7070704d;
    margin-bottom: 2rem;
  }

  .expand {
    font-weight: bold;
    margin: 1rem 0 0;
  }

  .detail {
    margin-top: 1rem;
  }
}