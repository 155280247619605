section#home {
  h3 {
    color: white;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  .subtitle {
    color: white;
    font-size: 14pt;
    margin-bottom: 1rem;
  }
}