section#project {
  padding: 150px 1rem 0;

  .title {
    padding-bottom: 2rem;
    border-bottom: 1px solid #7070704d;
    margin-bottom: 2rem;
  }

  .tab {
    font-size: 14pt;
    border-bottom: 4px solid #7070704d;
    padding: 1rem 0;
    color: #707070;

    &.active {
      font-weight: bold;
      color: var(--bs-primary);
      border-bottom-color: var(--bs-primary);
    }
  }
}