.root {
  padding: 1.5rem 2rem;
  
  color: #707070;
}

.selected {
  .root {
    border: 1px solid var(--bs-primary);
    padding: calc(1.5rem - 1px) calc(2rem - 1px);
  }
}