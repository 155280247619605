main#adblick {
  height: 100vh;
  display: flex;
  flex-direction: column;

  background-image: url(./img/page-bg.png);
  background-position: 100%;
  background-size: cover;

  z-index: -100;

  header {
    height: 128px;
    background-color: white;
    width: 100%;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    position: fixed;
    z-index: 100;

    .header-wrapper {
      height: 100%;
      max-width: 1640px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }

    img#logo {
      margin-top: -10px;
    }

    nav {
      flex: 1;
      margin-left: 4rem;

      a {
        font-size: 14pt;
        color: inherit;

        margin-right: 3rem;

        &.active {
          font-weight: bold;
          color: var(--bs-link-color);
        }
      }
    }

    menu .dropdown-toggle::after {
      display: none;
    }
  }
}
