.pwdChange {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  max-width: 500px;

  .formFooter {
    border-top: 1px solid #7070704d;
    padding: 1rem 0;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    button {
      min-width: 120px;
    }
  }
}