.btn {
  border-radius: 0.18rem;
}

.btn-primary,
.btn-primary:hover {
  color: white;
}

.btn-rounded {
  width: 53px;
  height: 53px;
  border-radius: 50%;

  i {
    font-size: 1.25rem;
  }
}