main#signup {
  height: 100vh;
  display: flex;
  flex-direction: column;

  background-image: url(../../img/page-bg.png);
  background-position: 100%;
  background-size: cover;

  padding: 3rem 8rem 3rem 10rem;

  overflow-y: scroll;

  font-size: 100%;

  header {
    padding-left: 4rem;
  }

  .signup-form {
    display: flex;
    max-height: 670px;
  }

  .subtitle {
    padding-bottom: 2rem;
    border-bottom: 1px solid #7070704d;
    margin-bottom: 2rem;
  }
}

.signup-step-guide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-right: 5rem;
  margin-bottom: 90px;

  .step-line {
    height: 64px;
    border-right: 3px solid #e2e2e2;
    margin-right: 32px;

    &.completed {
      border-color: var(--bs-primary);
    }
  }
}

.signup-step {
  display: flex;
  align-items: flex-start;

  .step-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-right: 2rem;

    .step-title {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 18px;
    }
    .step-subtitle {
      margin-bottom: 0;
    }
  }

  .step-icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    i {
      font-size: 1.5rem;
    }
  }

  &.completed .step-icon {
    background-color: var(--bs-primary);
  }
}

.signup-confirm {
  padding-left: 4rem;
  padding-top: 4rem;
  display: flex;

  .signup-email {
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}