input.form-control,
select.form-select {
  padding: 0.75rem 0.75rem;
  background-color: transparent;

  font-size: 90%;

  &:focus {
    background-color: transparent;
  }
}