$boxBackground: rgba(28,181,83,0.3);
$highlightBackground: rgba(28,181,83,0.1);
$highlightHeaderBackground: rgba(28,181,83,0.4);
$highlight: #286F3D;

table.box {

  tr.title {
    color: $highlight;
  }

  th, td {
    padding: 0.25rem 2.5rem;
  }

  th {
    background-color: $boxBackground;

    &.current {
      background-color: $highlightHeaderBackground;
      padding: 0.25rem 3rem;
    }
  }

  td.current {
    background-color: $highlightBackground;
    padding: 0.25rem 3rem;
  }

  th.frozen, td.frozen,
  th.frozen2, td.frozen2 {
    padding: 0.25rem 1.5rem;
    position: absolute;
    top: auto;
    clip-path: inset(0 -10px 0 0);
  }

  th.frozen, td.frozen {
    width: 26rem;
    left: 1rem;
    color: $highlight;
  }

  th.frozen2, td.frozen2 {
    width: 10.75rem;
    left: 27rem;
    box-shadow: 3px 0 3px 1px rgba(0, 0, 0, 0.08);
  }

  th.current {
    color: $highlight;
  }

  th.highlight, td.highlight {
    font-weight: bold;

    &.current {
      color: $highlight;
    }
  }
  th.colored, td.colored {
    color: $highlight;
  }
}